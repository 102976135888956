import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";
import React, { useState, useEffect, createRef } from "react";
import * as Commands from "../redux/document.commands";
import { CSSTransition } from "react-transition-group";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import Dropzone from "react-dropzone";
import _ from "lodash";
import ShowConfirmationDialog from "components/ShowConfirmationDialog";
import moment from "views/rai/ordini/components/ordini.create";

export default function UploadDocumentComponent(props) {
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState({});
  const [documentPeriod, setDocumentPeriod] = useState({});
  const [workersSelected, setWorkersSelected] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [metas, setMetas] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const coops = useSelector((state) =>
    state.coops.data.map((item) => ({ ...item, key: item.id }))
  );
  const [selectedCoop, setSelectedCoop] = useState();

  const workers = useSelector(
    (state) => _.get(state, "documents.workers", []),
    shallowEqual
  );
  const documentTypes = useSelector((state) => state.documentType.data);

  const leftPad = require("left-pad");

  const createDocument = async () => {
    setLoading(true);

    let res;

    if (
      documentType &&
      documentType.description &&
      ["mensilità aggiuntiva", "cu certificazione unica"].includes(
        documentType.description.toLowerCase()
      )
    ) {
      res = await Commands.CreateFromFiscalCode(
        documentPeriod.value,
        selectedFiles,
        documentType.id,
        selectedCoop.codiceGescoop
      );

      setLoading(false);
      console.log("@@@@@@@@@RES", res);

      if (!res.hasError) {
        const doAction = await ShowConfirmationDialog({
          title: "Risultato importazione",
          html: (
            <div style={{ textAlign: "justify" }}>
              Cooperativa: <b>{selectedCoop.codiceGescoop}</b>
              <br />
              Documenti classificati: <b>{res.data.validated}</b> <br />
              Documenti da classificare: <b>{res.data.notValidated}</b> <br />
              <br />
              <br />
              Nb. Per visualizzare i documenti ci potrebbero volere alcuni
              minuti di attesa
            </div>
          ),
          confirmBtnText: "Va bene",
          showCancel: false,
          canEscapeKeyCancel: false,
        });

        clearForm();
        props.toggle();
        return;
      }
    }

    if (
      documentType &&
      documentType.description &&
      documentType.description.toLowerCase() === "cedolino"
    ) {
      res = await Commands.CreatePayslip(
        documentPeriod.value,
        selectedFiles,
        workersSelected.map((w) => w.id),
        documentType.id,
        metas
      );
      setLoading(false);

      if (!res.hasError) {
        const doAction = await ShowConfirmationDialog({
          title: "Risultato importazione buste",
          html: (
            <div style={{ textAlign: "justify" }}>
              {res.data.stats.map((coop) => {
                if (!coop.coop || coop.coop.length === 0) {
                  return (
                    <>
                      <div style={{ color: "red" }}>
                        <b>Documenti da classificare manualmente</b>
                      </div>
                      Pagine: <b>{coop.totalCount}</b>, di cui{" "}
                      <b>{coop.busteCount} buste </b> e{" "}
                      <b>{coop.lulCount} lul</b>
                      <br />
                      <br />
                    </>
                  );
                }

                return (
                  <>
                    Cooperativa: <b>{coop.coop}</b>
                    <br />
                    Pagine: <b>{coop.totalCount}</b>, di cui{" "}
                    <b>{coop.busteCount} buste </b> e <b>{coop.lulCount} lul</b>
                    <br />
                    <br />
                  </>
                );
              })}
              Nb. Per visualizzare i documenti ci potrebbero volere alcuni
              minuti di attesa
            </div>
          ),
          confirmBtnText: "Va bene",
          showCancel: false,
          canEscapeKeyCancel: false,
        });

        clearForm();
        props.toggle();
        return;
      }
    }
    if (
      documentType &&
      documentType.description &&
      documentType.description.toLowerCase() === "timbrate"
    ) {
      res = await Commands.CreatePayslipOsra(
        documentPeriod.value,
        selectedFiles,
        workersSelected.map((w) => w.id),
        documentType.id,
        metas
      );
      setLoading(false);

      if (!res.hasError) {
        const doAction = await ShowConfirmationDialog({
          title: "Risultato importazione osra",
          html: (
            <div style={{ textAlign: "justify" }}>
              {res.data.stats.map((coop) => (
                <>
                  Cooperativa: <b>{coop.coop}</b>
                  <br />
                  Soci processati: <b>{coop.totalCount}</b>
                  <br />
                </>
              ))}
              Nb. Per visualizzare i documenti ci potrebbero volere alcuni
              minuti di attesa
            </div>
          ),
          confirmBtnText: "Va bene",
          showCancel: false,
          canEscapeKeyCancel: false,
        });

        clearForm();
        props.toggle();
        return;
      }
    } else if (
      documentType &&
      documentType.description &&
      documentType.description.toLowerCase() === "report luogo lavoro"
    ) {
      res = await Commands.CreatePayslipLuoghiLavoro(
        documentPeriod.value,
        selectedFiles,
        workersSelected.map((w) => w.id),
        documentType.id,
        metas
      );
      setLoading(false);

      if (!res.hasError) {
        const doAction = await ShowConfirmationDialog({
          title: "Risultato importazione osra",
          html: (
            <div style={{ textAlign: "justify" }}>
              {res.data.stats.map((coop) => (
                <>
                  Cooperativa: <b>{coop.coop}</b>
                  <br />
                  Soci processati: <b>{coop.totalCount}</b>
                  <br />
                </>
              ))}
              Nb. Per visualizzare i documenti ci potrebbero volere alcuni
              minuti di attesa
            </div>
          ),
          confirmBtnText: "Va bene",
          showCancel: false,
          canEscapeKeyCancel: false,
        });

        clearForm();
        props.toggle();
        return;
      }
    } else {
      res = await Commands.Create(
        documentPeriod.value,
        selectedFiles,
        workersSelected.map((w) => w.id),
        documentType.id,
        metas
      );
      setLoading(false);
    }

    if (res.hasError) {
      setErrorMessage(res.message);
      setSubmitError(true);
      return;
    }

    clearForm();
    props.toggle();
  };

  const clearForm = () => {};

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  const dropzoneRef = createRef();

  return (
    <Modal
      modalClassName="modal-black"
      isOpen={props.show}
      toggle={props.toggle}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Nuovo documento</h6>
      </div>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createDocument();
          }}
        >
          <FormGroup>
            <Label for="contratto">Tipologia documento</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={documentType}
              getOptionLabel={(item) => item.description}
              onChange={(value) => setDocumentType(value)}
              options={[
                {
                  value: "",
                  description: "Tipologia",
                  isDisabled: true,
                },
                ...documentTypes,
              ]}
              placeholder="Seleziona tipologia documento"
            />
            <FormText color="muted">Tipologia documento</FormText>
          </FormGroup>
          {documentType &&
          documentType.description &&
          (documentType.description.toLowerCase() === "cedolino" ||
            documentType.description.toLowerCase() === "timbrate" ||
            documentType.description.toLowerCase() ===
              "cu certificazione unica" ||
            documentType.description.toLowerCase() === "mensilità aggiuntiva" ||
            documentType.description.toLowerCase() ===
              "report luogo lavoro") ? null : (
            <>
              {" "}
              <FormGroup>
                <Label for="superiori">Lavoratore</Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  placeholder="Seleziona lavoratori..."
                  name="multipleSelect"
                  closeMenuOnSelect={true}
                  isMulti
                  value={workersSelected}
                  onChange={(value) => setWorkersSelected(value)}
                  getOptionLabel={(w) =>
                    w.cognomeNome +
                    " (" +
                    w.cooperativaAppartenenza +
                    "-" +
                    leftPad(w.matricola, 4, "0") +
                    ")"
                  }
                  getOptionValue={(w) => w.id}
                  options={[
                    {
                      value: "",
                      description: "Lavoratori",
                      isDisabled: true,
                    },
                    ...workers,
                  ]}
                />
                <FormText color="muted">
                  Selezionare i lavoratori ai quali il documento fa riferimento
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for="contratto">Periodo</Label>

              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                value={documentPeriod}
                getOptionLabel={(item) => item.description}
                getOptionValue={(w) => w.value}
                onChange={(value) => setDocumentPeriod(value)}
                options={[
                  {
                    value: 1579042800,
                    description: "Periodo",
                    isDisabled: true,
                  },
                  { value: 1642201200, description: "Gennaio 2022" },
                  { value: 1644879600, description: "Febbraio 2022" },
                  { value: 1647298800, description: "Marzo 2022" },
                  { value: 1649973600, description: "Aprile 2022" },
                  { value: 1652565600, description: "Maggio 2022" },
                  { value: 1655244000, description: "Giugno 2022" },
                  { value: 1657836000, description: "Luglio 2022" },
                  { value: 1660514400, description: "Agosto 2022" },
                  { value: 1663192800, description: "Settembre 2022" },
                  { value: 1665784800, description: "Ottobre 2022" },
                  { value: 1668466800, description: "Novembre 2022" },
                  { value: 1671058800, description: "Dicembre 2022" },
                  { value: 1673737200, description: "Gennaio 2023" },
                  { value: 1676415600, description: "Febbraio 2023" },
                  { value: 1678834800, description: "Marzo 2023" },
                  { value: 1681509600, description: "Aprile 2023" },
                  { value: 1684101600, description: "Maggio 2023" },
                  { value: 1686780000, description: "Giugno 2023" },
                  { value: 1689372000, description: "Luglio 2023" },
                  { value: 1692050400, description: "Agosto 2023" },
                  { value: 1694728800, description: "Settembre 2023" },
                  { value: 1697320800, description: "Ottobre 2023" },
                  { value: 1700002800, description: "Novembre 2023" },
                  { value: 1702594800, description: "Dicembre 2023" },
                  { value: 1705273200, description: "Gennaio 2024" },
                  { value: 1707951600, description: "Febbraio 2024" },
                  { value: 1710370800, description: "Marzo 2024" },
                  { value: 1713045600, description: "Aprile 2024" },
                  { value: 1715637600, description: "Maggio 2024" },
                  { value: 1718316000, description: "Giugno 2024" },
                  { value: 1720908000, description: "Luglio 2024" },
                  { value: 1723586400, description: "Agosto 2024" },
                  { value: 1726264800, description: "Settembre 2024" },
                  { value: 1728856800, description: "Ottobre 2024" },
                  { value: 1731538800, description: "Novembre 2024" },
                  { value: 1734130800, description: "Dicembre 2024" },
                  { value: 1736809200, description: "Gennaio 2025" },
                  { value: 1739487600, description: "Febbraio 2025" },
                  { value: 1741906800, description: "Marzo 2025" },
                  { value: 1744581600, description: "Aprile 2025" },
                  { value: 1747173600, description: "Maggio 2025" },
                  { value: 1749852000, description: "Giugno 2025" },
                  { value: 1752444000, description: "Luglio 2025" },
                  { value: 1755122400, description: "Agosto 2025" },
                  { value: 1757800800, description: "Settembre 2025" },
                  { value: 1760392800, description: "Ottobre 2025" },
                  { value: 1763074800, description: "Novembre 2025" },
                  { value: 1765666800, description: "Dicembre 2025" },
                ]}
                placeholder="Seleziona tipologia documento"
              />


                <FormText color="muted">
                  Periodo di compotenza del documento
                </FormText>
              </FormGroup>
            </>
          )}

          {documentType &&
          documentType.description &&
          (documentType.description.toLowerCase() ===
            "cu certificazione unica" ||
            documentType.description.toLowerCase() ===
              "mensilità aggiuntiva") ? (
            <>
              <FormGroup>
                <Label for="contratto">Cooperativa</Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  value={selectedCoop}
                  getOptionLabel={(item) => item.companyName}
                  getOptionValue={(item) => item.id}
                  onChange={(value) => {
                    setSelectedCoop(value);
                  }}
                  closeMenuOnSelect={false}
                  options={[
                    {
                      value: "",
                      companyName: "Coop",
                      isDisabled: true,
                      key: "coop",
                    },
                    ...coops,
                  ]}
                  placeholder="Seleziona cooperativa"
                />
                <FormText color="muted">Cooperativa di pertinenza</FormText>
              </FormGroup>
              <FormGroup>
                <Label for="contratto">Periodo</Label>

                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  value={documentPeriod}
                  getOptionLabel={(item) => item.description}
                  getOptionValue={(w) => w.value}
                  onChange={(value) => setDocumentPeriod(value)}
                  options={[
                    {
                      value: 1579042800,
                      description: "Periodo",
                      isDisabled: true,
                    },
                    { value: 1642201200, description: "Gennaio 2022" },
                    { value: 1644879600, description: "Febbraio 2022" },
                    { value: 1647298800, description: "Marzo 2022" },
                    { value: 1649973600, description: "Aprile 2022" },
                    { value: 1652565600, description: "Maggio 2022" },
                    { value: 1655244000, description: "Giugno 2022" },
                    { value: 1657836000, description: "Luglio 2022" },
                    { value: 1660514400, description: "Agosto 2022" },
                    { value: 1663192800, description: "Settembre 2022" },
                    { value: 1665784800, description: "Ottobre 2022" },
                    { value: 1668466800, description: "Novembre 2022" },
                    { value: 1671058800, description: "Dicembre 2022" },
                    { value: 1673737200, description: "Gennaio 2023" },
                    { value: 1676415600, description: "Febbraio 2023" },
                    { value: 1678834800, description: "Marzo 2023" },
                    { value: 1681509600, description: "Aprile 2023" },
                    { value: 1684101600, description: "Maggio 2023" },
                    { value: 1686780000, description: "Giugno 2023" },
                    { value: 1689372000, description: "Luglio 2023" },
                    { value: 1692050400, description: "Agosto 2023" },
                    { value: 1694728800, description: "Settembre 2023" },
                    { value: 1697320800, description: "Ottobre 2023" },
                    { value: 1700002800, description: "Novembre 2023" },
                    { value: 1702594800, description: "Dicembre 2023" },
                  ]}
                  placeholder="Seleziona tipologia documento"
                />

                <FormText color="muted">
                  Periodo di compotenza del documento
                </FormText>
              </FormGroup>
            </>
          ) : null}

          <FormGroup>
            <Dropzone
              // accept={"application/pdf"}
              ref={dropzoneRef}
              onDrop={(acceptedFiles) => {
                setSelectedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => {
                return (
                  <div className="drop-container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      {acceptedFiles.length > 0 ? (
                        ""
                      ) : (
                        <>
                          <p>Trascina i file qui</p>
                        </>
                      )}
                    </div>
                    <aside>
                      <ul>
                        {acceptedFiles.map((file) => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes
                          </li>
                        ))}
                      </ul>
                    </aside>
                  </div>
                );
              }}
            </Dropzone>
            <FormText color="muted">
              Per caricare più di un documento inserire i file in un archivio (
              .zip ) e caricare l'archivio.
            </FormText>
            <div></div>
          </FormGroup>

          {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
          <button style={{ display: "none" }} type="submit" />
        </form>

        <CSSTransition
          in={submitError}
          appear={true}
          unmountOnExit
          classNames="error"
          timeout={300}
        >
          <blockquote className="blockquote blockquote-danger">
            <p className="mb-0 text-danger">{errorMessage}</p>
          </blockquote>
        </CSSTransition>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={createDocument}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
